<template>
  <div
    class="tw-flex tw-flex-col tw-items-center tw-p-12 auth-container tw-m-auto"
  >
    <div class="tw-w-full">
      <h1 class="tw-font-poppins-bold tw-font-bold tw-text-3xl tw-text-center">
        Sign In
      </h1>
      <div
        class="tw-pt-2 tw-text-paragraph tw-flex-grow tw-text-center tw-mt-0 tw-text-base tw-leading-5 tw-font-montserrat-medium"
      >
        Don't have an account?
        <router-link
          :to="{ name: 'SignUp' }"
          class="tw-font-bold tw-text-primary tw-font-montserrat-medium tw-cursor-pointer"
        >
          Create One!
        </router-link>
      </div>
      <div
        v-if="error"
        class="tw-border tw-self-center tw-text-sm tw-border-red-500 tw-bg-red-300 tw-p-2 tw-rounded-lg tw-font-sans tw-mt-4"
      >
        {{ error.message }}
        <div v-if="error.code" class="tw-text-xs">{{ error.code }}</div>
      </div>
      <div
        class="tw-mt-8 tw-px-8 tw-font-medium tw-text-base tw-leading-4 tw-tracking-tight tw-font-poppins-medium"
      >
        <InputRow
          label="Email address"
          type="text"
          v-model="email"
          placeholder="name@mail.com"
        />

        <InputRow
          class="tw-mt-4"
          label="Password"
          type="password"
          v-model="password"
          placeholder="*******"
        />

        <div class="tw-flex tw-justify-center tw-items-center tw-gap-4 tw-mt-3">
          <router-link
            :to="{ name: 'ForgotPassword' }"
            class="tw-ml-auto tw-cursor-pointer forgot-password"
          >
            Forgot Password?
          </router-link>
        </div>

        <v-btn
          class="tw-w-full"
          @click="signInWithEmailAndPassword"
          color="primary"
        >
          Login
        </v-btn>
        <!-- :icon="require('@/assets/auth/google.png')" -->
        <v-btn class="tw-w-full" @click="signInWithGoogle"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 12C0 5.3726 5.3726 0 12 0C18.6274 0 24 5.3726 24 12C24 18.6274 18.6274 24 12 24C5.3726 24 0 18.6274 0 12Z"
              fill="white"
            />
            <path
              d="M11.9988 8.81143C12.9753 8.81143 13.6339 9.23323 14.0095 9.58568L15.4771 8.15278C14.5757 7.31498 13.4028 6.80078 11.9988 6.80078C9.96507 6.80078 8.20862 7.96788 7.35352 9.66653L9.03482 10.9723C9.45662 9.71853 10.6237 8.81143 11.9988 8.81143Z"
              fill="#EA4335"
            />
            <path
              d="M16.992 12.1158C16.992 11.6883 16.9574 11.3763 16.8822 11.0527H12V12.9825H14.8658C14.808 13.462 14.496 14.1843 13.8027 14.6696L15.4436 15.9407C16.4258 15.0336 16.992 13.6989 16.992 12.1158Z"
              fill="#4285F4"
            />
            <path
              d="M9.04063 13.0287C8.93083 12.7052 8.86728 12.3585 8.86728 12.0003C8.86728 11.642 8.93083 11.2954 9.03483 10.9718L7.35348 9.66602C7.00108 10.3709 6.79883 11.1625 6.79883 12.0003C6.79883 12.838 7.00108 13.6296 7.35348 14.3345L9.04063 13.0287Z"
              fill="#FBBC05"
            />
            <path
              d="M11.9989 17.2008C13.4029 17.2008 14.5816 16.7386 15.4425 15.9413L13.8016 14.6702C13.3625 14.9764 12.7731 15.1902 11.9989 15.1902C10.6238 15.1902 9.45672 14.283 9.04072 13.0293L7.35938 14.335C8.21447 16.0337 9.96513 17.2008 11.9989 17.2008Z"
              fill="#34A853"
            />
          </svg>

          Sign in with Google
        </v-btn>
        <!-- :icon="require('@/assets/auth/facebook.png')" -->
        <v-btn class="tw-w-full" @click="signInWithFacebook">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 12C0 5.3726 5.3726 0 12 0C18.6274 0 24 5.3726 24 12C24 18.6274 18.6274 24 12 24C5.3726 24 0 18.6274 0 12Z"
              fill="#3B5998"
            />
            <path
              d="M13.2519 19.0557V12.5271H15.0541L15.293 10.2773H13.2519L13.255 9.15125C13.255 8.56445 13.3108 8.25005 14.1536 8.25005H15.2802V6H13.4778C11.3127 6 10.5507 7.0914 10.5507 8.9268V10.2775H9.20117V12.5274H10.5507V19.0557H13.2519Z"
              fill="white"
            />
          </svg>
          Sign in with Facebook
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import InputRow from '@/views/auth/InputRow';

export default {
  name: 'SignInComponent',
  components: {
    InputRow
  },
  data() {
    return {
      email: '',
      password: '',
      error: null
    };
  },
  methods: {
    signInWithEmailAndPassword() {
      signInWithEmailAndPassword(getAuth(), this.email, this.password)
        .then(this.handleSignInSuccess)
        .catch(this.handleSignInError);
    },
    signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');

      signInWithPopup(getAuth(), provider)
        .then(this.handleSignInSuccess)
        .catch(this.handleSignInError);
    },
    signInWithFacebook() {
      const provider = new FacebookAuthProvider();
      provider.addScope('public_profile');
      provider.addScope('email');

      signInWithPopup(getAuth(), provider)
        .then(this.handleSignInSuccess)
        .catch(this.handleSignInError);
    },
    handleSignInError(err) {
      switch (err.code) {
      case 'auth/popup-closed-by-user':
        return (this.error = null);
      case 'auth/user-not-found':
        return (this.error = {
          message: 'The provided e-mail address is not registered.'
        });
      case 'auth/wrong-password':
        return (this.error = {
          message:
              'The password does not belong to the provided e-mail address.'
        });
      case 'auth/invalid-email':
        return (this.error = {
          message: 'The e-mail address you provided is invalid.'
        });
      default:
        this.$emit('error', err);
        return (this.error = {
          message:
              'An error has occured. Please check your data and try again.',
          code: err.code
        });
      }
    },
    handleSignInSuccess(data) {
      if (!data.providerId && !data.user.emailVerified) {
        return (this.error = {
          message: 'Please verify your e-mail first.'
        });
      } else {
        this.$store.state.user.data.emailVerified = true;
      }
      this.$emit('success', data);
      this.$router.replace({
        name: 'LandingPage'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';

.auth-container {
  @include respond-to(sm-xl) {
    width: $screen-sm-min;
  }
}

.v-btn {
  border-radius: 104px !important;
  box-shadow: none !important;
  font-family: MontserratSemiBold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  margin-top: 24px;
}

svg {
  margin-right: 12px;
}

h1 {
  font-family: PoppinsBold;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #28262e;
}

.forgot-password {
  font-family: PoppinsSemiBold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: #747378;
  margin-bottom: 8px;
}

.guest-btn {
  background: #ffe4ec !important;
  color: #d62a53;
}
</style>
