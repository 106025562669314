<template>
  <div class="tw-h-full tw-w-full tw-flex tw-justify-center">
    <SignInComponent />
  </div>
</template>

<script>
import SignInComponent from '@/views/auth/SignIn/SignInComponent';

export default {
  components: {
    SignInComponent,
  },
};
</script>

<style>
</style>
